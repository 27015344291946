.WindowPage {
  display: flex;
  flex-direction: column;
  height: var(--vh);
  background-position: center 80%;
  background-size: 100% 100%;
  color: #fbfbfb;
  position: relative;
  overflow: hidden;
}

.WindowPage__plant {
  position: absolute;
  z-index: 1;
  width: 165px;
  height: 195px;
  left: calc(12vw - 211px + 50vw);
  top: 37%;
}

.WindowPage__pot-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -36px;
  margin: 0 auto;
}

.WindowPage__moon {
  width: 171px;
  height: 154px;
  position: absolute;
  left: 2%;
  top: 1%;
}

.Window__stars {
  position: absolute;
  width: 100%;
  top: 0;
  animation: shining 2s infinite;
}

.Window__stars--hidden {
  animation-name: none;
}

.WindowPage__wall {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.Window__stars > img {
  width: 100%;
}

.WindowPage__window {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--vh);
  width: 100%;
  background-position-x: center;
  background-position-y: 30%;
}

.WindowPage__situation {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 25%;
  margin: 0 auto;
  width: 65%;
  align-self: center;
  font-size: 15px;
  line-height: var(--global-line-height);
}

.WindowPage__situation.ja {
  width: 80%;
}

.WindowPage__situation.es,
.WindowPage__situation.en {
  font-size: 16px;
}

.WindowPage__question-group {
  position: absolute;
  bottom: 6%;
  z-index: 1;
}

.WindowPage__question {
  font-size: 15px;
}

.WindowPage__question.es,
.WindowPage__question.en {
  width: 85%;
  font-size: 17px;
  margin: 0 auto;
}

.WindowPage__option-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 5%;
}

.WindowPage__option {
  font-size: 14px;
  width: 40%;
  color: #3f3f3f;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin-top: 16px;
  padding: 9px 12px;
  min-height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WindowPage__option.ja {
  padding: 0 12px;
  min-height: 63px;
}

.WindowPage__ending-text {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 18%;
  font-size: 15px;
  padding: 0 10%;
  line-height: var(--global-line-height);
}

.WindowPage__ending-text.en,
.WindowPage__ending-text.es {
  font-size: 16px;
}

.WindowPage__ending-remind {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 21px;
  font-size: 13px;
  z-index: 1;
}

.WindowPage__ending-remind.en,
.WindowPage__ending-remind.es {
  font-size: 15px;
}

.WindowPage__tap-notice {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -56px;
  font-size: 14px;
}

.WindowPage__tap-notice.tc {
  font-size: 16px;
}

.WindowPage__tap-notice.en,
.WindowPage__tap-notice.es {
  font-size: 15px;
}

.WindowPage__stars-lottie {
  will-change: transform;
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
}

.WindowPage__concept {
  position: absolute;
  top: 20%;
  padding: 0 13%;
  font-size: 14px;
  line-height: 23px;
}

.WindowPage__concept.en,
.WindowPage__concept.es {
  font-size: 16px;
}

.app-icon {
  width: 21px;
  height: 21px;
  position: relative;
  display: inline-block;
  margin-left: 1px;
}

.app-icon-inner {
  height: 100%;
  position: absolute;
  top: 5px;
}

.app-icon-inner > img {
  height: 100%;
}

.app-name--bold {
  font-family: sans-serif;
  font-weight: bolder;
}

.WindowPage__logo {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 85px;
}

.WindowPage__loading {
  position: absolute;
  bottom: 20%;
  width: 100%;
  font-size: 12px;
}

.WindowPage__loading.en,
.WindowPage__loading.es {
  font-size: 15px;
}

.WindowPage__loading-ready,
.WindowPage__loading-unready {
  position: absolute;
  left: 0;
  right: 0;
}

.dot--1,
.dot--2,
.dot--3 {
  color: #fff;
  animation: generatingDots 2.4s infinite;
}

.dot--2 {
  animation-delay: 0.6s;
}

.dot--3 {
  animation-delay: 1.2s;
}

@keyframes generatingDots {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
