.Name {
  position: absolute;
  height: var(--vh);
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fbfbfb;
}

.Name__text {
  font-size: 15px;
  line-height: 21px;
  margin-top: 25vh;
  padding: 0 10%;
}

.Name__text.en,
.Name__text.es {
  font-size: 16px;
}

.Name__input {
  font-family: 'amatic', sans-serif;
  width: 65%;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: #fbfbfb;
  border-bottom: 1px solid #fbfbfb;
  -webkit-user-select: text;
  border-radius: 0;
  margin-top: 36px;
}

.Name__input:focus {
  outline: none;
  color: #fbfbfb;
}

.Name__input:-webkit-autofill,
.Name__input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fbfbfb;
  -webkit-box-shadow: none;
  border: none;
}

.Name__submit {
  position: relative;
  margin-top: 45px;
}

.Name__submit-text {
  position: absolute;
  font-size: 17px;
  left: 0;
  right: 0;
  top: 7px;
}

.Name__submit-text.en,
.Name__submit-text.es {
  top: 7px;
}

.Name__submit-text.tc,
.Name__submit-text.sc {
  top: 4px;
}

.Name__submit-text.ko {
  top: 8px;
}
