.Desktop {
  height: var(--vh);
  width: 100%;
  background-repeat: repeat;
  color: #fff;
  display: flex;
  justify-content: center;
}

.Desktop__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 90%;
}

.Desktop__content-left {
  max-width: 400px;
}

.Desktop__phone {
  width: 100%;
}

.Desktop__phone > img {
  width: 100%;
}

.Desktop__content-right {
  display: flex;
  height: 420px;
}

.Desktop__split-line {
  height: 100%;
  align-self: center;
}

.Desktop__split-line > img {
  height: 100%;
}

.Desktop__content-right-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 27px;
}

.Desktop__title {
  font-size: 51px;
  text-shadow: 0px 0px 32px #e7a38d, 0px 0px 32px #e7a38d, 0px 0px 32px #e7a38d;
}

.Desktop__title.en {
  font-family: 'amatic';
}

.Desktop__intro {
  font-size: 34px;
  line-height: 135%;
  text-shadow: 0px 0px 32px #e7a38d, 0px 0px 32px #e7a38d, 0px 0px 32px #e7a38d;
}

.Desktop__content-right-bottom {
  display: flex;
  align-items: center;
  justify-content: left;
}

.Desktop__qrcode-container {
  height: 85px;
}

.Desktop__qrcode-container > img {
  height: 100%;
  box-shadow: 0 0 9px 3px #e7a38d;
}

.Desktop__suggestion {
  font-size: 23px;
  text-shadow: 0 0 16px #e7a38d, 0 0 16px #e7a38d;
  margin-left: 32px;
  line-height: 135%;
}

.Desktop__forest-logo-container {
  height: 60px;
  position: absolute;
  bottom: 6%;
  right: 4%;
}

.Desktop__forest-logo-container > img {
  height: 100%;
}
