.Loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.Loader__flower {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 27px;
  transform-origin: 14px 15px;
  animation: boom 2s infinite ease;
}

.Loader__plant {
  position: relative;
}

.Loader__progress {
  margin-top: 16px;
  font-size: 16px;
  font-family: sans-serif;
  color: #b79b77;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes boom {
  0% {
    transform: scale(0);
  }
  33% {
    transform: scale(0);
  }
  43% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
