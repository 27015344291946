.Page3 {
  height: var(--vh);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 36px 0;
  position: relative;
  background-position: center;
  overflow: hidden;
}

.Page3__window-light {
  pointer-events: none;
  position: absolute;
  mix-blend-mode: soft-light;
  right: -60px;
  top: -55px;
  width: 400px;
  height: 300px;
}

.Page3__window-light > img {
  width: 100%;
  height: 100%;
}

.Page3__env-light {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.question-3 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  z-index: 1;
  padding: 0 8%;
}

.Page3__question-text {
  margin-top: 12px;
  padding: 0 12px;
  line-height: var(--global-line-height);
}

.Page3__question-text.en,
.Page3__question-text.es {
  font-size: 17px;
}

.book_1,
.book_2,
.book_3,
.book_4 {
  position: absolute;
  bottom: 0%;
  right: 2%;
  pointer-events: none;
}

.Page3__phone {
  position: absolute;
  left: -128px;
  bottom: 0%;
  transform: rotate(14deg);
  pointer-events: none;
}

.Page3__phone--light {
  z-index: 2;
}

.Page3__phone--ring {
  width: 28px;
  height: 42px;
  background-color: #000;
  position: absolute;
  left: 7%;
  bottom: 28%;
  transform: rotate(-20deg);
}

.Page3__dizzy {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.Page3__dizzy-top,
.Page3__dizzy-bottom {
  height: 100%;
  max-height: 40%;
  background-color: #333;
}

.question-4 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-4-text {
  line-height: var(--global-line-height);
}
.question-4-text.es,
.question-4-text.en {
  font-size: 17px;
}

.question-4-text.ko {
  padding: 0 18px;
}

.q4__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 9px;
}

.question-4__result {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.question-4__result-text-area {
  font-size: 18px;
  width: 80%;
  line-height: var(--global-line-height);
  font-style: italic;
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.question-5,
.question-6 {
  position: absolute;
  z-index: 1;
  left: 6%;
  right: 6%;
  top: 7%;
}

.question-5__description {
  line-height: var(--global-line-height);
  padding: 0 5%;
}

.question-5__description.ko {
  padding: 0 30px;
  font-size: 15px;
}

.question-5__text,
.question-6__text {
  font-size: 17px;
  margin-top: 9px;
  padding: 0 18px;
  line-height: var(--global-line-height);
}

.question-5__options,
.question-6__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.question-5__option,
.question-6__option {
  font-size: 14px;
  width: 40%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin-top: 16px;
  padding: 9px 12px;
  min-height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-5__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  line-height: var(--global-line-height);
}

.question-5__overlay-situation {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  font-size: 15px;
  z-index: 2;
  padding: 0 10%;
}

.q7_Situation {
  position: absolute;
  z-index: 1;
  top: 15%;
  color: #fbfbfb;
  padding: 0 12%;
  line-height: var(--global-line-height);
}

.q7_Situation-text--1 {
}

.q7_Situation-text--1.en,
.q7_Situation-text--1.es {
  font-size: 16px;
}

.q7_Situation-text--2 {
  margin-top: 48px;
}

.q7_Situation-text--2.ja {
  font-size: 13px;
}

.q7_Situation-text--2.es,
.q7_Situation-text--2.en {
  font-size: 15px;
}

.Page3__tap-notice {
  margin-top: 36px;
  font-style: normal;
  font-size: 14px;
}

.Page3__tap-notice.en,
.Page3__tap-notice.es {
  font-size: 15px;
}

.Page3__memo {
  position: absolute;
  pointer-events: none;
  bottom: 240px;
  left: 15%;
  width: 82px;
  height: 87px;
}

.Page3__notebook {
  position: absolute;
  pointer-events: none;
  bottom: 230px;
  right: 0;
  transform: rotate(8deg);
}

.Page3__note--hidden {
  opacity: 0;
}

.Page3__book-click-area {
  width: 125px;
  height: 169px;
  position: absolute;
  right: 18px;
  top: 45px;
  pointer-events: all;
}

.Page3__phone-click-area {
  position: absolute;
  pointer-events: all;
  width: 72px;
  height: 140px;
  top: 83px;
  right: 113px;
  filter: blur(0);
  opacity: 0;
}

.Page3__phone-click-area--light {
  opacity: 1;
  background-color: #fff;
  filter: blur(12px) !important;
}
