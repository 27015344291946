.Audio {
  position: fixed;
  right: 3%;
  top: 2%;
  width: 34px;
  z-index: 4;
  pointer-events: all;
}

.Music__button-image {
  width: 100%;
}
