.App {
  width: 100%;
  font-size: 16px;
  color: #3f3f3f;
  text-align: center;
  position: relative;
}

.App--overflow-hidden {
  overflow: hidden;
}

.App--hide {
  display: none !important;
}

.lock {
  pointer-events: none;
  touch-action: none;
}
