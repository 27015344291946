* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

img {
  pointer-events: none;
}

body {
  margin: 0;
  overflow-x: hidden;
}

#root {
  font-family: 'en-400', 'sc-400', 'tc-400', 'ko-400', 'ja-400';

  --line-height-en: 24px;
  --line-height-tc: 27px;
  --line-height-sc: 27px;
  --line-height-ko: 20px;
  --line-height-ja: 1.6;
}

.Desktop {
  font-family: 'tc-400', 'sc-400', 'ko-400', 'ja-400', 'amatic';
}

.bold {
  font-family: 'en-700', 'sc-500', 'tc-500', 'ko-700', 'ja-500';
}
