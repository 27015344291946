.Modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #494949;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__close {
  position: absolute;
  width: 30px;
  top: 12px;
  left: 12px;
}

.Modal__close path {
  fill: var(--modal-icon-fill-color);
  stroke: var(--modal-icon-fill-color);
}

.Modal__content {
  position: relative;
  width: 80%;
  height: 455px;
  margin-top: -16px;
  padding: 24px 10% 12px;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  border-radius: 8px;
}

.Modal__title {
  font-size: 16px;
}

.Modal__title.en {
  font-family: 'amatic';
}

.Modal__title.es {
  font-size: 15px;
}

.Modal__title.ja {
  font-size: 14px;
}

.Modal__pal-name {
  margin-top: 9px;
  font-size: 13px;
}

.Modal__pal-description {
  margin-top: 6px;
  font-size: 12px;
  line-height: var(--global-line-height);
}

.Modal__pal-image {
  height: 180px;
  align-self: center;
  margin: -12px 0;
}

.Modal__find-pal-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Modal__find-pal-title {
  display: flex;
  align-items: center;
  margin: 0 -16%;
}

.Modal__find-pal-title-text {
  font-size: 12px;
  margin: 0 5px;
}

.Modal__find-pal-break {
  height: 1px;
  flex: 1;
}

.Modal__find-pal-description {
  font-size: 12px;
  line-height: var(--global-line-height);
}

.Modal__to-hash-tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal__click-text {
  font-size: 12px;
  font-weight: 500;
}

.Modal__arrow-icon {
  margin: 0 9px;
}

.Modal__arrow-icon path {
  fill: var(--modal-icon-fill-color);
}

.Modal__icon-container {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 27px;
  margin-right: 12px;
}

.Modal__icon-container.en {
  margin: 0 6px;
}

.Modal__icon-container path {
  fill: var(--modal-icon-fill-color);
}

.Modal__icon-container > svg {
  width: 100%;
}

@media screen and (max-height: 465px) {
  .Modal__content {
    height: 390px;
    width: 87%;
  }
}
