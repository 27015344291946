.EasterEgg__Page1 {
  height: var(--vh);
  background-position: center 90%;
  position: relative;
}

.EasterEgg__remind {
  color: #fbfbfb;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;
  font-size: 14px;
}
.EasterEgg__remind.en,
.EasterEgg__remind.es {
  font-size: 15px;
}

.EasterEgg__description {
  color: #fbfbfb;
  position: absolute;
  width: 100%;
  top: 12%;
  padding: 0 12%;
  line-height: var(--global-line-height);
  text-shadow: 0 0 12px #272e56, 0 0 12px #272e56, 0 0 12px #272e56,
    0 0 12px #272e56;
}

.EasterEgg__description.ja {
  top: 8%;
}

.EasterEgg__fireworks {
  position: absolute;
  width: 90%;
  height: 90%;
  top: -10%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.EasterEgg__wall {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.EasterEgg__wall > img {
  width: 100%;
}

.EasterEgg__moon {
  width: 171px;
  height: 154px;
  position: absolute;
  left: 2%;
  top: 1%;
}

.EasterEgg__stars.hidden {
  animation-name: none;
}

.EasterEgg__stars {
  position: absolute;
  width: 100%;
  top: -16px;
  animation: shining 2s infinite;
}

.EasterEgg__stars > img {
  width: 100%;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.EasterEgg__plant {
  position: absolute;
  bottom: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 165px;
  height: 195px;
  z-index: 1;
}

.EasterEgg__pot-shadow {
  width: 268px;
  height: 151px;
  position: absolute;
  bottom: -2vh;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.EasterEgg__Page2 {
  height: 1000px;
  display: flex;
  align-items: flex-end;
  background-position: center;
  position: relative;
}

.EasterEgg__player {
  margin-bottom: 248px;
}

.EasterEgg__player-image {
  width: 100%;
}

.EasterEgg__gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(2, 6, 85, 0.38) 45%,
    rgba(255, 255, 255, 0) 100%
  );
}

.EasterEgg__concept {
  position: absolute;
  top: 14%;
  padding: 0 13%;
  font-size: 13px;
  line-height: var(--global-line-height);
  color: #fbfbfb;
}
.EasterEgg__concept.ja {
  top: 20%;
}

.EasterEgg__concept.en,
.EasterEgg__concept.es {
  font-size: 16px;
  line-height: 22px;
}

.EasterEgg_small-logo {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 85px;
}

.EasterEgg__loading-text {
  position: absolute;
  bottom: 20%;
  width: 100%;
  font-size: 12px;
  color: #fbfbfb;
}

.EasterEgg__loading-text.en,
.EasterEgg__loading-text.es {
  font-size: 15px;
}

.EasterEgg__loading-ready,
.EasterEgg__loading-unready {
  position: absolute;
  left: 0;
  right: 0;
}

.EasterEgg__lottie-stars {
  will-change: transform;
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
}
