.StartPage {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--vh);
  position: absolute;
  z-index: 5;
  background-repeat: repeat;
}

.Cover__lottie {
  width: 100%;
}

.Cover__lottie > img {
  width: 100%;
}

.cover-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  letter-spacing: 0.23px;
  justify-content: space-evenly;
  align-items: center;
}

.Cover__content-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cover-intro {
  color: #f47256;
  font-size: 25px;
}
.cover-intro.es {
  font-size: 33px;
  font-family: 'amatic';
}
.cover-intro.en {
  font-size: 32px;
  font-family: 'amatic';
}
.cover-intro.ja {
  font-size: 23px;
}

.Cover__description {
  color: #ffe5db;
  margin-top: 2px;
  font-size: 15px;
}

.Cover__description.en {
  font-size: 19px;
  font-family: 'amatic';
}

.Cover__description.es {
  font-size: 20px;
  font-family: 'amatic';
}

.Cover__description.ko {
  font-family: 'ko-400';
  width: 80%;
  margin-top: 12px;
  line-height: 18px;
}

.cover-start {
  color: #fff;
  font-family: 'amatic';
  font-size: 35px;
  margin: 0 auto 5px;
  animation: startFadeInAndOut 3s infinite linear;
  border-bottom: 1px solid;
  line-height: 30px;
}

.cover-start.ko {
  font-family: 'ko-700';
  font-size: 18px;
}
.cover-start.ja {
  font-family: 'ja-500';
  font-size: 18px;
  border-bottom: 2px solid;
}

.Cover__powered-by {
  position: absolute;
  bottom: 4px;
  right: 6px;
  width: 100px;
}

.Cover__small-logo {
  width: 60px;
  margin-left: 6px;
}

@keyframes startFadeInAndOut {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}
