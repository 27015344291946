.Phone {
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  position: fixed;
  z-index: 2;
}

.Phone > img {
  position: absolute;
  left: -50%;
  right: -50%;
  top: -3%;
  margin: 0 auto;
  width: 129%;
  height: 129%;
}

.Phone__speaker {
  background-color: #333;
  position: absolute;
  top: 24px;
  width: 12%;
  height: 5px;
  border-radius: 4px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.Phone__front-cam {
  position: absolute;
  right: -21%;
  left: 0;
  margin: 0 auto;
  width: 12px;
  top: 20px;
  background-color: #333;
  padding: 6px;
  border-radius: 50%;
}

.Phone__screen {
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  position: absolute;
  top: 50px;
  left: 5%;
  right: 5%;
  bottom: 6vh;
  font-family: sans-serif;
}

.Phone__status-bar {
  background-color: #aaa;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  padding: 0 6px;
}

.Phone__signal {
  display: flex;
  align-items: center;
  height: 100%;
}

.Phone__signal-img {
  height: 100%;
}

.Phone__signal-img > img {
  height: 100%;
}

.Phone__signal-text {
  margin-left: 4px;
}

.Phone__status-time {
  position: absolute;
  left: 12px;
  right: 0;
}

.Phone__battery {
  height: 100%;
}

.Phone__battery > img {
  height: 100%;
}

.Phone__nav-bar {
  background-color: #bbb;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
}

.Phone__back,
.Phone__menu {
  height: 80%;
}

.Phone__friend {
  padding-top: 1px;
  font-size: 16px;
}

.Phone__menu > img,
.Phone__back > img {
  height: 100%;
}

.Phone__content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: left;
  flex: 1;
  padding-top: 16px;
  background-size: 100%;
}

.Phone__avatar {
  height: 30px;
  position: absolute;
  left: -50px;
}

.Phone__avatar > img {
  height: 100%;
}

.Phone__triangle {
  position: absolute;
  width: 12px;
  left: -10px;
  top: 15px;
}

.Phone__triangle--flip {
  position: absolute;
  width: 12px;
  right: 25px;
  top: 177px;
  transform: scaleX(-1);
}

.Phone__triangle > img {
  width: 100%;
}

.Phone__first-msg,
.Phone__second-msg,
.Phone__last-msg {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  background-color: #ddd;
  color: #333;
  padding: 6px 12px 9px 12px;
  min-height: 48px;
  max-width: 60%;
  display: flex;
  align-items: center;
  background-size: 100%;
}

.Phone__second-msg.es {
  max-width: 65%;
}

.Phone__first-msg {
  margin: 0 auto 0 64px;
}

.Phone__second-msg {
  margin: 30px 10% 0 auto;
  background-size: auto;
}

.Phone__last-msg {
  margin: 30px auto 0 64px;
  background-size: 100% 100%;
}

.Phone__typing {
  width: 50px;
  display: flex;
  justify-content: space-evenly;
}

.Phone__typing > div {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #fbfbfb;
  animation-name: dotDotDot;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.Phone__typing-dot--1 {
  animation-delay: 0s;
}

.Phone__typing-dot--2 {
  animation-delay: 500ms;
}

.Phone__typing-dot--3 {
  animation-delay: 1s;
}

@keyframes dotDotDot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.Phone__time--right,
.Phone__time--left {
  color: #666;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.Phone__time--right {
  right: 6px;
}

.Phone__time--left {
  left: 9px;
}

.Phone__bottom-bar {
  display: flex;
  align-items: center;
  padding: 12px 0;
  background-color: #aaa;
  background-size: 100% 100%;
}

.Phone__bottom-items {
  flex: 1;
  display: flex;
  align-items: center;
}

.Phone__add {
  width: 10%;
  display: none;
}

.Phone__item {
  height: 24px;
  width: 24px;
  background-color: #666;
  margin-right: 4%;
}

.Phone__tools {
  height: 24px;
  margin: 0 3%;
}

.Phone__tools > img {
  height: 100%;
}

.Phone__text-input {
  background-color: #fff;
  border-radius: 13px;
  height: 27px;
  flex: 1;
}

.Phone__send {
  align-self: flex-start;
  width: 8%;
  margin: 0 3%;
}

.Phone__send > img {
  width: 100%;
}

.Phone__msgs-group {
  font-family: sans-serif;
  width: 72%;
  border-radius: 18px;
  background-color: #ddd;
  margin: auto 0 auto auto;
}

.Phone__msg {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 18px;
}

.Phone__break-line {
  height: 1px;
  background-color: #999;
  width: 80%;
  margin: 0 auto;
}
