.Page2 {
  height: 1000px;
  width: 100%;
  background-position: center center;
  position: relative;
}

.Page2__question {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  position: absolute;
  top: 16%;
  z-index: 1;
}

.Page2__description {
  color: #535353;
  font-size: 16px;
  padding: 0 5%;
  line-height: var(--global-line-height);
}

.Page2__description.sc,
.Page2__description.tc,
.Page2__description.ko {
  font-size: 15px;
  padding: 0 24px;
}

.Page2__question-text {
  font-size: 18px;
  margin-top: 9px;
  line-height: var(--global-line-height);
}

.Page2__question-text.ko {
  font-size: 16px;
}

.Page2__option-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 6px;
}

.option-normal {
  font-size: 13px;
  flex-basis: 40%;
  margin-top: 12px;
  padding: 0 1em;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.option-normal.ja {
  font-size: 12px;
}

.Page2__lottie-container {
  position: absolute;
  top: 15vh;
  left: 0;
  right: 0;
}
