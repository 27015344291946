.Prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  font-size: 18px;
  color: #5f5f5f;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.Prompt__image {
  margin-top: 36px;
}
