.background-invisible {
  position: absolute;
  z-index: -1;
  display: block;
  width: 100vw;
  height: var(--vh);
}

.Result {
  height: var(--vh);
  width: 100%;
  display: flex;
  flex-direction: column;
  touch-action: none;
  position: relative;
}

.Result__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a2042;
  z-index: 5;
}

.Result__content {
  height: var(--vh);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2vh 12vw 0;
  position: relative;
  overflow: hidden;
}

.Result__enter-name {
  font-size: 15px;
  margin-top: 25vh;
  padding: 0 24px;
  line-height: 145%;
}

.Result__name-input {
  font-family: 'amatic', sans-serif;
  width: 65%;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  border: 0;
  border-bottom: 1px solid;
  margin-top: 10vh;
  -webkit-user-select: text;
  border-radius: 0;
}

.Result__name-input:focus {
  outline: none;
}

.Result__name-submit {
  margin-top: 8vh;
  font-size: 17px;
}

.Result__poster-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10%;
  left: 0;
  opacity: 0;
  z-index: 2;
}

.Result__poster-img > img {
  height: 100%;
  width: 100%;
  pointer-events: auto !important;
}

.Result__content-border {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: solid 9px;
  border-bottom: 0;
  z-index: 2;
  pointer-events: none;
}

.Result__circle--1 > img,
.Result__circle--2 > img,
.Result__circle--3 > img,
.Result__circle--4 > img {
  width: 100%;
}

.Result__circle--1,
.Result__circle--2,
.Result__circle--3,
.Result__circle--4 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
}

.Result__circle--1,
.Result__circle--3 {
  left: -41px;
}

.Result__circle--2,
.Result__circle--4 {
  right: -41px;
}

.Result__circle--1,
.Result__circle--2 {
  top: -41px;
  z-index: 2;
}

.Result__circle--3,
.Result__circle--4 {
  bottom: 0;
  z-index: 1;
}

.Result__circle--3-screenshot,
.Result__circle--4-screenshot {
  bottom: -29px !important;
}

.Result__arrow {
  position: absolute;
  height: 30px;
  animation: arrowMove 1.5s infinite;
  top: 5%;
}

.Result__arrow.en,
.Result__arrow.es {
  left: -22%;
}

.Result__arrow.tc,
.Result__arrow.sc {
  left: -8%;
}

.Result__arrow.ko {
  left: -24%;
}

.Result__arrow.ja {
  left: -10%;
}

.Result__arrow.hidden {
  opacity: 0;
}

@keyframes arrowMove {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-9px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.Result__arrow > img {
  height: 100%;
}

.Result__info {
  flex: 1 1;
  -webkit-box-pack: justify;
  display: flex;
  flex-direction: column;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  align-items: center;
}

.Result__name {
  height: 30px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  margin-top: 3px;
  font-size: 20px;
  font-family: 'amatic';
}

.Result__name--none {
  height: 0px;
}

.Result__name > img {
  height: 100%;
}

.Result__analysis {
  font-size: 12px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Result__image {
  height: 265px;
  align-self: center;
  margin: -48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Result__image.ja {
  margin: -40px 0 -56px;
}

.Result__image.es {
  margin: -40px 0 -56px;
}

.Result__image.ko {
  height: 265px;
}

.Result__image > img {
  height: 100%;
}

.Result__flower-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Result__flower-name > img {
  height: 100%;
}

.Result__flower-name.tc,
.Result__flower-name.sc,
.Result__flower-name.ja,
.Result__flower-name.ko {
  height: 37px;
}

.Result__flower-name.en,
.Result__flower-name.es {
  height: 43px;
}

.Result__quote {
  font-size: 14px;
}

.Result__description {
  font-size: 12px;
  min-height: 70px;
  display: flex;
  align-items: center;
}

.Result__description.ko {
  line-height: 16px;
}

.Result__bottom-row {
  display: flex;
  font-size: 12px;
  width: 85%;
}

.Result__bottom-row.en {
  width: 100%;
}

.Result__bottom-row.es {
  width: 110%;
}

.Result__bottom-row.ja {
  width: 100%;
}

.Result__bottom-col-pal {
  position: relative;
  z-index: 3;
}

.Result__poster-find-buddy {
  flex: 1;
  min-width: 160px;
  margin-top: -12px;
}

.hashtag-bold {
  font-weight: 700;
  font-family: 'en-700';
}

.Result__poster-icons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.Result__poster-icon {
  height: 18px;
}

.Result__poster-icon > img {
  height: 100%;
  margin: 0 4px;
}

.Result__poster-icon path {
  fill: var(--twitter-prompt-color);
}

.Result__bottom-col {
  flex-basis: 50%;
}

.Result__pal-title,
.Result__place-title {
  font-weight: 600;
}

.Result__pal,
.Result__place {
  margin-top: 5px;
}

.Result__save-image {
  margin-bottom: 9px;
  font-size: 12px;
}

.Result__save-image.ko {
  padding: 0 81px;
  line-height: 14px;
}

.Result__footer {
  display: flex;
  align-items: center;
  margin: 0 -12vw;
  height: 50px;
  position: relative;
  z-index: 1;
}

.Result__footer--sp {
  display: flex;
  margin: 0 -12vw;
  height: 50px;
  z-index: 0;
  align-items: center;
  padding: 0 9%;
  justify-content: space-evenly;
}

.Result__cta-logo-group.sp {
  position: relative;
}
.Result__cta-logo-group {
  position: absolute;
  left: 4%;
}
.Result__cta-logo-group.ko {
  left: 7%;
}
.Result__cta-logo-group.sc,
.Result__cta-logo-group.tc {
  left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Result__cta-logo-group.en,
.Result__cta-logo-group.es {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Result__cta-logo {
  height: 27px;
}

.Result__cta-logo.ko {
  height: 20px;
}
.Result__cta-logo.sc,
.Result__cta-logo.tc {
  height: 20px;
}
.Result__cta-logo.en,
.Result__cat-logo.es {
  height: 21px;
}
.Result__cta-logo.ja {
  height: 20px;
}

.Result__cta-logo > img {
  height: 100%;
}

.Result__cta-logo--sp {
  height: 27px;
}

.Result__cta-logo--sp > img {
  height: 100%;
}

.Result__cta-logo-text {
  font-family: sans-serif;
  font-size: 12px;
  margin-top: 1px;
  font-weight: bolder;
}
.Result__cta-logo-text.sc,
.Result__cta-logo-text.tc,
.Result__cta-logo-text.ko {
  font-size: 13px;
}

.Result__cta-button {
  position: relative;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.Result__cta-button > img {
  height: 100%;
}

.Result__cta-button--sp {
  height: 36px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Result__cta-button--sp > img {
  height: 100%;
}

.Result__cta-button-text {
  font-family: 'amatic', sans-serif;
  position: absolute;
  white-space: nowrap;
  left: 50%;
}

.Result__cta-button-text.en {
  font-size: 21px;
  top: 3px;
}
.Result__cta-button-text.es {
  font-size: 21px;
  top: 3px;
}

.Result__cta-button-text.tc,
.Result__cta-button-text.sc {
  font-size: 15px;
  top: 6px;
}

.Result__cta-button-text.ko {
  font-size: 16px;
  top: 5px;
}

.Result__cta-button-text.ja {
  font-size: 16px;
  top: 5px;
}

.Result__qrcode-field {
  height: 40px;
  min-width: 200px;
  align-self: center;
  font-size: 12px;
  margin-bottom: 16px;
  transform: translate(0, -3px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: 300;
}

.Result__qrcode {
  transform: rotate(45deg);
  height: 100%;
  margin: 0 16px;
}

.Result__qrcode > img {
  height: 100%;
}

.Result__qrcode-text--left,
.Result__qrcode-text--right {
  height: 100%;
  width: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Result__qrcode-text--left div,
.Result__qrcode-text--right div {
  white-space: nowrap;
}

.Result__footer-screenshot {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin: 0 -12vw;
  padding: 0 42px;
  font-family: sans-serif;
  font-size: 12px;
}

.Result__screenshot-space {
  height: 20px;
}

.Result__footer-screenshot-url {
  /* height: 100%; */
  line-height: 30px;
  height: 30px;
  font-weight: bolder;
  /* display: flex;
    align-items: center; */
}

.Result__footer-screenshot-logo {
  height: 18px;
  margin-right: 6px;
}

.Result__footer-screenshot-logo > img {
  height: 100%;
}

.Result__prompt {
  position: absolute;
  bottom: -22px;
  right: -2px;
  width: 117px;
  height: 108px;
  transform: rotate(-16deg);
  z-index: 3;
}

.Result__plant {
  position: absolute;
  bottom: 0;
  right: 0;
}

.Result__prompt-msg.ko > img {
  transform: scaleX(0.85);
}
.Result__prompt-msg.ja > img {
  transform: scaleY(1.2);
}

.Result__prompt-msg.es {
  transform: translate(9px, 0px);
}

/* .Result__prompt-msg.en > img {
  transform: scaleX(-2.5) scaleY(3.5);
  position: relative;
  top: -18px;
  right: -30px;
} */

.Result__prompt-msg-text {
  font-family: sans-serif;
  color: #6c6c6c;
  position: absolute;

  width: 100%;
  height: 34px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Result__prompt-msg-text.sc,
.Result__prompt-msg-text.tc {
  font-size: 12px;
}

.Result__prompt-msg-text.en,
.Result__prompt-msg-text.es {
  font-size: 12px;
  font-family: 'en-400';
}

.Result__prompt-msg-text.ko {
  font-size: 13px;
  line-height: 16px;
  top: 1px;
}

.Result__prompt-msg-text.ja {
  font-size: 12px;
}

.Result__prompt-click-text {
  position: absolute;
  color: #fff;
}

.Result__prompt-click-text.tc,
.Result__prompt-click-text.sc {
  top: 42px;
  left: 7px;
  font-size: 12px;
  font-family: sans-serif;
}

.Result__prompt-click-text.en,
.Result__prompt-click-text.es {
  top: 38px;
  left: 3px;
  font-size: 22px;
  font-family: 'amatic';
}

.Result__prompt-click-text.ko {
  top: 44px;
  left: 3px;
  font-size: 12px;
  font-family: sans-serif;
}

.Result__prompt-click-text.ja {
  top: 45px;
  left: -8px;
  font-size: 12px;
  font-family: sans-serif;
}

.Result__top-keyword {
  font-size: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: -21px;
  font-weight: bold;
}

.Result__twitter-prompt-group {
  position: absolute;
  bottom: -10px;
  z-index: 2;
  left: 2px;
}

.Result__twitter-prompt {
  width: 45px;
  transform: rotate(18deg);
}

.Result__twitter-prompt path {
  fill: var(--twitter-prompt-color);
  stroke: var(--twitter-prompt-stroke-color);
  stroke-width: 1px;
}

.Result__arrow-container {
  width: 24px;
  position: absolute;
  animation: arrowMove 1.5s infinite;
  left: 0px;
  top: -3px;
}

.Result__arrow-container.es {
  left: -13px;
}

.Result__arrow-container path {
  fill: var(--twitter-prompt-color);
}

.Result__find-my-buddy {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  position: relative;
  justify-content: center;
  flex: 1 1;
  z-index: 3;
  margin-top: 6px;
}

@media screen and (max-height: 465px) {
  .Result__image {
    height: 180px;
    width: auto;
    margin: -25px 100% -34px;
  }
  .Result__image > img {
    height: 100%;
  }
  .Result__footer {
    margin: auto -12vw;
  }
  .Result__flower-name.tc,
  .Result__flower-name.sc {
    height: 33px;
  }
  .Result__flower-name.en,
  .Result__flower-name.es {
    height: 39px;
  }
  .Result__flower-name.ja {
    height: 36px;
  }
  .Result__name {
    margin-top: 0;
    height: 28px;
  }

  .Result__circle--1,
  .Result__circle--2,
  .Result__circle--3,
  .Result__circle--4 {
    width: 90px;
    height: 90px;
    border-radius: 45px;
  }

  .Result__cta-logo {
    height: 24px;
  }

  .Result__cta-button {
    height: 32px;
  }

  .Result__cta-button-text.en,
  .Result__cta-button-text.es {
    font-size: 20px;
    top: 3px;
    right: 16px;
  }
  .Result__analysis {
    margin-top: -6px;
  }

  .Result__bottom-row {
    height: 40px;
    width: 90%;
  }

  .Result__description {
    margin-top: -9px;
    height: auto;
  }

  .Result__arrow {
    height: 24px;
    top: 9%;
  }
  .Result__cta-button-text.tc,
  .Result__cta-button-text.sc {
    top: 4px;
    left: 19px;
  }
}

@media screen and (min-height: 700px) {
  .Result__info {
    justify-content: space-around;
  }

  .Result__name {
    height: 45px;
  }
  .Result__quote {
    font-size: 15px;
    padding: 0px 42px;
  }
  .Result__description {
    font-size: 14px;
    height: auto;
  }

  .Result__bottom-row {
    height: 70px;
    width: 88%;
    font-size: 13px;
  }
}

#root {
  /* frame and cta bg color */
  --result-color-1: #99b26f;
  --result-color-2: #a8ae80;
  --result-color-3: #b56835;
  --result-color-4: #d2a392;
  --result-color-5: #af7f47;
  --result-color-6: #82b381;
  --result-color-7: #e49584;
  --result-color-8: #e8b75a;
  --result-color-9: #849b62;
  --result-color-10: #eb9d6d;
  --result-color-11: #98af73;
  --result-color-12: #f3c54e;
  --result-color-13: #d88282;
  --result-color-14: #49426d;
  --result-color-15: #b9d685;
  --result-color-16: #aec8d3;
  --result-color-17: #323b50;

  /* primary text color */
  --result-text-color-primary-1: #4f6517;
  --result-text-color-primary-2: #9c6b31;
  --result-text-color-primary-3: #b56835;
  --result-text-color-primary-4: #85543f;
  --result-text-color-primary-5: #814b0d;
  --result-text-color-primary-6: #368329;
  --result-text-color-primary-7: #6c9d70;
  --result-text-color-primary-8: #a06812;
  --result-text-color-primary-9: #627743;
  --result-text-color-primary-10: #8a592b;
  --result-text-color-primary-11: #7d8c4b;
  --result-text-color-primary-12: #b9884b;
  --result-text-color-primary-13: #c86b6b;
  --result-text-color-primary-14: #ffe399;
  --result-text-color-primary-15: #d68e8a;
  --result-text-color-primary-16: #a58c79;
  --result-text-color-primary-17: #d2b260;

  /* twitter prompt text color */
  --result-twitter-prompt-1: #4f6517;
  --result-twitter-prompt-2: #9c6b31;
  --result-twitter-prompt-3: #b56835;
  --result-twitter-prompt-4: #85543f;
  --result-twitter-prompt-5: #814b0d;
  --result-twitter-prompt-6: #368329;
  --result-twitter-prompt-7: #6c9d70;
  --result-twitter-prompt-8: #a06812;
  --result-twitter-prompt-9: #627743;
  --result-twitter-prompt-10: #8a592b;
  --result-twitter-prompt-11: #7d8c4b;
  --result-twitter-prompt-12: #b9884b;
  --result-twitter-prompt-13: #c86b6b;
  --result-twitter-prompt-14: #49426d;
  --result-twitter-prompt-15: #c86b6b;
  --result-twitter-prompt-16: #a58c79;
  --result-twitter-prompt-17: #323b50;

  /* secondary text color */
  --result-text-color-secondary-1: #848e44;
  --result-text-color-secondary-2: #7c8351;
  --result-text-color-secondary-3: #de7800;
  --result-text-color-secondary-4: #ca7360;
  --result-text-color-secondary-5: #b6782c;
  --result-text-color-secondary-6: #65a863;
  --result-text-color-secondary-7: #bc7160;
  --result-text-color-secondary-8: #e09101;
  --result-text-color-secondary-9: #9d745d;
  --result-text-color-secondary-10: #cf662f;
  --result-text-color-secondary-11: #e1b928;
  --result-text-color-secondary-12: #dfae28;
  --result-text-color-secondary-13: #68795d;
  --result-text-color-secondary-14: #c1acfd;
  --result-text-color-secondary-15: #6f974f;
  --result-text-color-secondary-16: #6e95a8;
  --result-text-color-secondary-17: #de9475;

  /* cta text color */
  --result-cta-color-1: #f8ffcf;
  --result-cta-color-2: #ffffdf;
  --result-cta-color-3: #f9ba5c;
  --result-cta-color-4: #ffefe8;
  --result-cta-color-5: #f9d89b;
  --result-cta-color-6: #ddeecc;
  --result-cta-color-7: #ffe2dc;
  --result-cta-color-8: #ffefb7;
  --result-cta-color-9: #d5e0bc;
  --result-cta-color-10: #ffe9de;
  --result-cta-color-11: #ffeea3;
  --result-cta-color-12: #ffeebe;
  --result-cta-color-13: #f2cece;
  --result-cta-color-14: #b7a6e9;
  --result-cta-color-15: #f7ffe0;
  --result-cta-color-16: #e9eff2;
  --result-cta-color-17: #a5a5c6;

  /* qrcode text */
  --result-qrcode-color-1: #848e44;
  --result-qrcode-color-2: #7c8351;
  --result-qrcode-color-3: #b56835;
  --result-qrcode-color-4: #85543f;
  --result-qrcode-color-5: #814b0d;
  --result-qrcode-color-6: #65a863;
  --result-qrcode-color-7: #bc7160;
  --result-qrcode-color-8: #a06812;
  --result-qrcode-color-9: #627743;
  --result-qrcode-color-10: #8a592b;
  --result-qrcode-color-11: #7d8c4b;
  --result-qrcode-color-12: #b9884b;
  --result-qrcode-color-13: #c86b6b;
  --result-qrcode-color-14: #c1acfd;
  --result-qrcode-color-15: #6f974f;
  --result-qrcode-color-16: #6e95a8;
  --result-qrcode-color-17: #a5a5c6;

  --result-circle-rotate-1-1: 165deg;
  --result-circle-rotate-1-2: -107deg;
  --result-circle-rotate-1-3: 74deg;
  --result-circle-rotate-1-4: -13deg;

  --result-circle-rotate-2-1: -180deg;
  --result-circle-rotate-2-2: -91deg;
  --result-circle-rotate-2-3: 90deg;
  --result-circle-rotate-2-4: 3deg;

  --result-circle-rotate-3-1: 180deg;
  --result-circle-rotate-3-2: -87deg;
  --result-circle-rotate-3-3: 91deg;
  --result-circle-rotate-3-4: 0deg;

  --result-circle-rotate-4-1: -116deg;
  --result-circle-rotate-4-2: -26deg;
  --result-circle-rotate-4-3: 156deg;
  --result-circle-rotate-4-4: 66deg;

  --result-circle-rotate-5-1: 178deg;
  --result-circle-rotate-5-2: -93deg;
  --result-circle-rotate-5-3: 85deg;
  --result-circle-rotate-5-4: 0deg;

  --result-circle-rotate-6-1: 151deg;
  --result-circle-rotate-6-2: -119deg;
  --result-circle-rotate-6-3: 61deg;
  --result-circle-rotate-6-4: -26deg;

  --result-circle-rotate-7-1: 36deg;
  --result-circle-rotate-7-2: 119deg;
  --result-circle-rotate-7-3: -68deg;
  --result-circle-rotate-7-4: -154deg;

  --result-circle-rotate-8-1: 123deg;
  --result-circle-rotate-8-2: -147deg;
  --result-circle-rotate-8-3: 34deg;
  --result-circle-rotate-8-4: -57deg;

  --result-circle-rotate-9-1: -70deg;
  --result-circle-rotate-9-2: -102deg;
  --result-circle-rotate-9-3: 73deg;
  --result-circle-rotate-9-4: -102deg;

  --result-circle-rotate-10-1: -25deg;
  --result-circle-rotate-10-2: 65deg;
  --result-circle-rotate-10-3: -110deg;
  --result-circle-rotate-10-4: 156deg;

  --result-circle-rotate-11-1: -9deg;
  --result-circle-rotate-11-2: 80deg;
  --result-circle-rotate-11-3: -101deg;
  --result-circle-rotate-11-4: 171deg;

  --result-circle-rotate-12-1: 125deg;
  --result-circle-rotate-12-2: -142deg;
  --result-circle-rotate-12-3: 40deg;
  --result-circle-rotate-12-4: -50deg;

  --result-circle-rotate-13-1: -162deg;
  --result-circle-rotate-13-2: -72deg;
  --result-circle-rotate-13-3: 117deg;
  --result-circle-rotate-13-4: 20deg;

  --result-circle-rotate-14-1: -100deg;
  --result-circle-rotate-14-2: -10deg;
  --result-circle-rotate-14-3: 169deg;
  --result-circle-rotate-14-4: 83deg;

  --result-circle-rotate-15-1: 118deg;
  --result-circle-rotate-15-2: -148deg;
  --result-circle-rotate-15-3: 30deg;
  --result-circle-rotate-15-4: -58deg;

  --result-circle-rotate-16-1: -138deg;
  --result-circle-rotate-16-2: 177deg;
  --result-circle-rotate-16-3: 24deg;
  --result-circle-rotate-16-4: 0deg;

  --result-circle-rotate-17-1: 0deg;
  --result-circle-rotate-17-2: 0deg;
  --result-circle-rotate-17-3: -180deg;
  --result-circle-rotate-17-4: -180deg;
}
