.Forest {
  height: calc(var(--vh) * 1.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  touch-action: none;
  will-change: transform;
  position: relative;
}

.Forest__space {
  height: calc(var(--vh) * 0.5);
}

.Forest__to-result {
  font-size: 13px;
  height: 27px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 9px;
  width: 124px;
  justify-content: flex-end;
}

.Forest__content {
  height: var(--vh);
  color: #fff;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding-top: 27px;
  padding-bottom: 20px;
}

.Forest__to-result > img {
  height: 56%;
  margin-left: 6px;
}

.Forest__logo {
  height: 45px;
}

.Forest__logo > img {
  height: 100%;
  pointer-events: inherit;
}

.Forest__slogan {
  font-size: 14px;
  padding: 0 12%;
  margin-bottom: 3px;
}

.Forest__cards-container {
  height: 245px;
  width: 100%;
}

.Forest__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Forest__download-text {
  font-size: 13px;
}

.Forest__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  width: 85%;
}

.Forest__store {
  background-color: #8cc914;
}

.Forest__redeem {
  background-color: #debe4c;
}

.Forest__store,
.Forest__redeem {
  width: 120px;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
}

.Forest__store {
  box-shadow: 0 4px 0 #68960c;
}

.Forest__redeem {
  box-shadow: 0 4px 0 #c5a845;
}

.Forest__store:active {
  box-shadow: 0 2px 0 #68960c;
  transform: translateY(2px);
}

.Forest__redeem:active {
  box-shadow: 0 2px 0 #c5a845;
  transform: translateY(2px);
}

.Forest__try-again {
  font-size: 13px;
  margin-top: 4px;
  text-decoration: underline;
  display: flex;
  align-self: center;
}

.Forest__try-again--hidden {
  visibility: hidden;
}

@media screen and (max-height: 465px) {
  .Forest__content {
    padding-bottom: 12px;
  }
  .Forest__to-result {
    top: 4px;
  }

  .Forest__logo {
    height: 36px;
  }

  .Forest__slogan {
    margin: 0;
    font-size: 12px;
    padding: 0 16px;
  }

  .Forest__cards-container {
    height: 200px;
  }

  .Forest__download-text {
    font-size: 12px;
  }

  .Forest__store,
  .Forest__redeem {
    width: 108px;
    height: 32px;
    line-height: 32px;
  }
}

@media screen and (min-height: 700px) {
  .Forest__logo {
    height: 56px;
  }
  .Forest__cards-container {
    height: 270px;
  }
}
