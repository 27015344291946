.Page1 {
  height: var(--vh);
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0px 10%;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.Page1__cloud {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 345px;
  height: 350px;
}

@media screen and (min-height: 635px) {
  .Page1__cloud {
    top: 10%;
  }
}

.Page1__cloud > img {
  width: 100%;
}

.Page1__question-text {
  font-size: 18px;
  position: absolute;
  left: 0;
  right: 0;
  top: 5%;
}

.Page1__question-text.ko {
  font-size: 15px;
}

.Page1__option {
  font-size: 13px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 2;
  bottom: 59px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 140px;
}

.Page1__option.es {
  width: 149px;
}

.Page1__option-text {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  justify-content: center;
}

.Page1__option-text.ko {
  font-size: 12px;
}

.Page1__option-image {
  z-index: 2;
  height: 150px;
  width: 150px;
}

.Page1__option-image > img {
  height: 100%;
}

.Page1__seed-shadow {
  position: absolute;
  width: 150px;
  height: 150px;
}

.Page1__seed-shadow > img {
  width: 100%;
}

.Page1__intro {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: absolute;
  top: 8%;
  width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.Page1__intro-text {
  font-size: 16px;
  line-height: var(--global-line-height);
}

.Page1__intro-text.en,
.Page1__intro-text.es {
  font-size: 17px;
}

.Page1__recommendation {
  font-size: 14px;
  margin-top: 36px;
}

.Page1__recommendation.en,
.Page1__recommendation.es {
  font-size: 15px;
}

.Page1__recommendation.ja {
  margin-top: 24px;
}

.Page1__click-notice {
  margin-top: 20px;
  font-size: 14px;
}

.Page1__click-notice.es,
.Page1__click-notice.en {
  font-size: 15px;
}

.Page1__mud {
  position: absolute;
  bottom: 101px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 138px;
  z-index: 2;
}

.Page1__mud > img {
  width: 100%;
}

.Page1__pot-image-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -70px;
  margin: 0 auto;
  z-index: 1;
  width: 255px;
}

.Page1__pot-image-container > img {
  width: 100%;
}
