.Cards {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.Cards__card {
  height: 100%;
  border-radius: 5px;
  width: 273px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.Cards__card-top {
  border-radius: 5px 5px 0 0;
  flex: 4;
  background-size: 100% auto;
  background-position: center;
}

.Cards__card-bottom {
  flex: 3;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  color: #464646;
  font-size: 13px;
  padding: 0 21px;
  display: flex;
  align-items: center;
}

@media screen and (max-height: 465px) {
  .Cards__card-bottom {
    font-size: 12px;
    padding: 7px 21px;
    overflow: hidden;
  }
  .Cards__card-top {
    background-size: 100% auto;
    background-position: center;
  }
  .Cards__card {
    width: 225px;
  }
}
